import { writable } from 'svelte/store';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

export const store = writable(null);

export const getUserData = async () => {
  const provider = new GoogleAuthProvider();
  const auth = getAuth();

  try {
    return signInWithPopup(auth, provider);
  } catch (error) {
    console.log(error);
  }
};
