<script>
  export let name, firebaseApp;
  import LoginButton from './LoginButton.svelte';
  import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth';
  import {
    collection,
    doc,
    getFirestore,
    query,
    getDocs,
    where,
  } from 'firebase/firestore/lite';
  import { store } from './auth';
  import Status from './Status.svelte';
  import { MaterialApp } from 'svelte-materialify';

  let loggedIn;
  let auth = getAuth(firebaseApp);
  onAuthStateChanged(auth, (user) => {
    if (user === null) {
      loggedIn = false;
      return;
    }
    loggedIn = true;
    name = user.displayName;
  });

  function logout() {
    signOut(auth);
  }
</script>

<MaterialApp>
  {#if loggedIn}
    <div
      style="
      display: flex;
      width: 100%;
      background-color: #F1E7C6;
      justify-content: space-between;
      height: 245px;
      padding: 0;
      "
    >
      <div
        style="display: flex;  justify-content: space-between; width: 100%; margin-top: 72px;"
      >
        <div>
          <p
            style="
        
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 48px;
    margin-left: 105px;
  
    "
          >
            Hi {name}!
          </p>
          <p style="margin-left: 106px">
            Let’s book your slot on the dev environment
          </p>
        </div>

        <button
          style="
        width: 103px;
        height: 24px;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-decoration-line: underline;
        margin-right: 90px;
        "
          on:click={logout}>Log out</button
        >
      </div>
    </div>
    <Status />
  {:else if loggedIn === false}
    <LoginButton {firebaseApp} />
  {/if}
</MaterialApp>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@100&display=swap');
  * {
    font-family: 'Poppins', sans-serif;
    padding: 0;
    margin: 0;
  }
</style>
