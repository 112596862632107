<script>
  import {
    collection,
    query,
    setDoc,
    onSnapshot,
    getFirestore,
    doc,
    runTransaction,
  } from 'firebase/firestore';
  import { get } from 'svelte/store';
  import { store } from './auth';
  import { Snackbar, Container, Row, Col, Button } from 'svelte-materialify';
  import { getAuth } from 'firebase/auth';

  const db = getFirestore();
  const user = getAuth().currentUser;
  let devs = [];
  let showSnackbar = false;
  let snackbarText = '';

  const q = query(collection(db, 'DEV1'));
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    devs = querySnapshot.docs.map((doc) => doc.data());
  });

  async function bookDev(dev) {
    try {
      snackbarText = await runTransaction(db, async (transaction) => {
        const devRef = doc(db, 'DEV1', dev);
        const snapshot = await transaction.get(devRef);
        if (snapshot.data().reserved) {
          throw `Too late! Environment ${dev} is already reserved 😥`;
        }
        transaction.update(devRef, { reserved: true, who: user.displayName });
        return `🎉You have successfully booked an ${dev} environment!🎉`;
      });
      showSnackbar = true;
    } catch (err) {
      snackbarText = err;
      showSnackbar = true;
    }
  }

  async function unbookDev(dev) {
    try {
      snackbarText = await runTransaction(db, async (transaction) => {
        const devRef = doc(db, 'DEV1', dev);
        const snapshot = await transaction.get(devRef);
        if (!snapshot.data().reserved) {
          throw `Environment ${dev} is not reserved`;
        }
        transaction.update(devRef, { reserved: false, who: '' });
        return `🎉You have successfully unbooked ${dev} environment!🎉`;
      });
      showSnackbar = true;
    } catch (err) {
      snackbarText = err;
      showSnackbar = true;
    }
  }
</script>

<Container>
  <Snackbar
    class="flex-column"
    bind:active={showSnackbar}
    top
    center
    timeout={3000}><p>{snackbarText}</p></Snackbar
  >
  <Row>
    <Col>Environment name</Col>
    <Col>Status</Col>
    <Col>Details</Col>
    <Col />
  </Row>
  {#each devs as dev}
    <Row
      style="
        margin-bottom: 33px;
        height: 112px;
        background-color: #F7F6F6; 
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        align-content: space-around;
    "
    >
      <Col>{dev.name}</Col>
      <Col>{dev.reserved ? '🔴' : '🟢'}</Col>
      <Col>{dev.who === '' ? 'free' : dev.who}</Col>
      <Col
        ><Button
          style="background-color: #49B138"
          disabled={dev.reserved && dev.who !== user.displayName}
          on:click={() =>
            dev.reserved && dev.who === user.displayName
              ? unbookDev(dev.name.replace(/\s+/g, ''))
              : bookDev(dev.name.replace(/\s+/g, ''))}
          >{dev.reserved && dev.who === user.displayName
            ? 'unbook'
            : 'Book'}</Button
        ></Col
      >
    </Row>
  {/each}
</Container>
