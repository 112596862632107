import App from './App.svelte'; // Your web app's Firebase configuration

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDvECBFbRea3ilLHFHzQtDoU2uIuiMkBqw',
  authDomain: 'dev-reservation-hack.firebaseapp.com',
  projectId: 'dev-reservation-hack',
  storageBucket: 'dev-reservation-hack.appspot.com',
  messagingSenderId: '363696570802',
  appId: '1:363696570802:web:586c4534e7f172349864ab',
  measurementId: 'G-K1ZCTZRTQ8',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const app = new App({
  target: document.body,
  props: {
    name: 'world',
    firebaseApp: firebaseApp,
  },
});

export default app;
